import React, { useState } from "react";
import { Button } from "./components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./components/ui/select";
import { Textarea } from "./components/ui/textarea";
import {
  Facebook,
  Instagram,
  Menu,
  Book,
  Feather,
  Send,
  UserPlus,
  LogIn,
  Users,
  DollarSign,
  ThumbsUp,
  ThumbsDown,
  RefreshCw,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./components/ui/dropdown-menu";

export default function LandingPage() {
  const [theme, setTheme] = useState("");
  const [duration, setDuration] = useState("");
  const [tone, setTone] = useState("");
  const [homily, setHomily] = useState("");
  const [formality, setFormality] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const generateHomily = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";
      const response = await fetch(`${apiUrl}/api/v1/generate-homily`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ theme, duration, tone, formality }),
      });

      let data;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        data = await response.json();
      } else {
        // If the response is not JSON, get the text content
        const text = await response.text();
        throw new Error(`Unexpected response: ${text}`);
      }

      if (!response.ok) {
        throw new Error(
          data.details || data.error || "Failed to generate homily"
        );
      }

      setHomily(data.homily);
    } catch (err) {
      setError(`Error: ${err.message}`);
      console.error("Error generating homily:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-gray-100">
      <header className="sticky top-0 z-10 bg-gray-900 bg-opacity-80 backdrop-blur-md border-b border-gray-800">
        <div className="container flex items-center justify-between h-16 px-4">
          <div className="flex flex-col">
            <h1 className="text-xl font-serif font-bold">OmelIA</h1>
            <p className="text-xs italic text-gray-400">
              Videmus nunc per speculum
            </p>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <Menu className="h-6 w-6" />
                <span className="sr-only">Menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 bg-gray-800 border-gray-700">
              <DropdownMenuItem className="text-gray-200 focus:bg-gray-700 focus:text-white">
                <UserPlus className="mr-2 h-4 w-4" />
                <span>Registrati</span>
              </DropdownMenuItem>
              <DropdownMenuItem className="text-gray-200 focus:bg-gray-700 focus:text-white">
                <LogIn className="mr-2 h-4 w-4" />
                <span>Accedi</span>
              </DropdownMenuItem>
              <DropdownMenuItem className="text-gray-200 focus:bg-gray-700 focus:text-white">
                <Users className="mr-2 h-4 w-4" />
                <span>Chi siamo</span>
              </DropdownMenuItem>
              <DropdownMenuItem className="text-gray-200 focus:bg-gray-700 focus:text-white">
                <DollarSign className="mr-2 h-4 w-4" />
                <span>Prezzi</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>

      <main className="flex-1">
        <section className="relative h-[50vh] flex items-center justify-center">
          <div className="absolute inset-0 bg-[url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/closeup-shot-male-holding-bible-with-blurred-background-black-white_181624-20323-5q3HNEzyzKqkrQ6II3FdEAVS2U4LCZ.jpg')] bg-cover bg-center"></div>
          <div className="absolute inset-0 bg-black bg-opacity-60 backdrop-blur-sm"></div>
          <div className="relative z-10 text-center">
            <h2 className="text-4xl font-serif font-bold mb-4">
              Ispira la Tua Congregazione
            </h2>
            <p className="text-xl max-w-md mx-auto">
              Crea omelie potenti con ispirazione divina e assistenza AI
            </p>
          </div>
        </section>

        <section className="container px-4 py-12 space-y-8">
          <div className="max-w-2xl mx-auto space-y-6 bg-gray-800 p-6 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105">
            <h2 className="text-2xl font-serif font-semibold text-center">
              Genera un'Omelia
            </h2>
            {/* <Input
              placeholder="Inserisci l'argomento del sermone o il versetto biblico..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="bg-gray-700 border-gray-600 placeholder-gray-400"
            /> */}
            <Select value={theme} onValueChange={setTheme}>
              <SelectTrigger className="bg-gray-700 border-gray-600">
                <SelectValue placeholder="Scegli un tema" />
              </SelectTrigger>
              <SelectContent className="bg-gray-700 border-gray-600">
                <SelectItem value="messa_domenicale">
                  Messa Domenicale
                </SelectItem>
                <SelectItem value="matrimonio">Matrimonio</SelectItem>
                <SelectItem value="battesimo">Battesimo</SelectItem>
                <SelectItem value="funerale">Funerale</SelectItem>
              </SelectContent>
            </Select>
            <Select value={duration} onValueChange={setDuration}>
              <SelectTrigger className="bg-gray-700 border-gray-600">
                <SelectValue placeholder="Seleziona la durata" />
              </SelectTrigger>
              <SelectContent className="bg-gray-700 border-gray-600">
                <SelectItem value="1">1 minuto</SelectItem>
                <SelectItem value="3">3 minuti</SelectItem>
                <SelectItem value="5">5 minuti</SelectItem>
              </SelectContent>
            </Select>
            <Select value={tone} onValueChange={setTone}>
              <SelectTrigger className="bg-gray-700 border-gray-600">
                <SelectValue placeholder="Seleziona il tono" />
              </SelectTrigger>
              <SelectContent className="bg-gray-700 border-gray-600">
                <SelectItem value="felice">Felice</SelectItem>
                <SelectItem value="solenne">Solenne</SelectItem>
                <SelectItem value="ispirato">Ispirante</SelectItem>
                <SelectItem value="confortante">Confortante</SelectItem>
              </SelectContent>
            </Select>
            <Select value={formality} onValueChange={setFormality}>
              <SelectTrigger className="bg-gray-700 border-gray-600">
                <SelectValue placeholder="Seleziona il livello di vicinanza con l'auditorio" />
              </SelectTrigger>
              <SelectContent className="bg-gray-700 border-gray-600">
                <SelectItem value="formale">Formale</SelectItem>
                <SelectItem value="amichevole">Amichevole</SelectItem>
                <SelectItem value="scherzoso">Scherzoso</SelectItem>
              </SelectContent>
            </Select>
            <Button
              onClick={generateHomily}
              className="w-full bg-gray-100 text-gray-900 hover:bg-gray-200"
              disabled={isLoading}
            >
              <Feather className="mr-2 h-4 w-4" />
              {isLoading ? "Generating..." : "Generate Homily"}
            </Button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>

          {homily && (
            <div className="max-w-2xl mx-auto space-y-4 bg-gray-800 p-6 rounded-lg shadow-lg animate-fade-in">
              <h2 className="text-2xl font-serif font-semibold">
                La Tua Omelia Ispirata
              </h2>
              <Textarea
                value={homily}
                readOnly
                className="min-h-[200px] bg-gray-700 border-gray-600"
              />
              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <Button variant="outline" size="sm">
                    <ThumbsUp className="h-4 w-4 mr-2" />
                    Mi piace
                  </Button>
                  <Button variant="outline" size="sm">
                    <ThumbsDown className="h-4 w-4 mr-2" />
                    Non mi piace
                  </Button>
                </div>
                <div className="flex space-x-2">
                  <Button variant="outline" size="sm">
                    <RefreshCw className="h-4 w-4 mr-2" />
                    Prova di nuovo
                  </Button>
                  <Button variant="outline" size="sm">
                    <Book className="h-4 w-4 mr-2" />
                    Salva Bozza
                  </Button>
                  <Button size="sm">
                    <Send className="h-4 w-4 mr-2" />
                    Condividi
                  </Button>
                </div>
              </div>
            </div>
          )}
        </section>
      </main>

      <footer className="bg-gray-800 py-8">
        <div className="container px-4">
          <div className="grid grid-cols-2 gap-4">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 text-sm hover:text-gray-300 transition-colors"
            >
              <Instagram className="h-6 w-6" />
              <span>Instagram</span>
            </a>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 text-sm hover:text-gray-300 transition-colors"
            >
              <Facebook className="h-6 w-6" />
              <span>Facebook</span>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
